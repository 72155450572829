.word-wrapper {
  position: relative;
  transition: all 0.2s ease-in-out;
}

.word-wrapper.highlighted {
  background-color: rgba(255, 215, 0, 0.3);
  border-radius: 4px;
  transform: scale(1.05);
}

/* Add transition styles for page changes */
.page-transition-enter {
  opacity: 0;
  transform: translateX(100%);
}

.page-transition-enter-active {
  opacity: 1;
  transform: translateX(0%);
  transition: opacity 600ms, transform 600ms;
}

.page-transition-exit {
  opacity: 1;
  transform: translateX(0%);
}

.page-transition-exit-active {
  opacity: 0;
  transform: translateX(-100%);
  transition: opacity 600ms, transform 600ms;
} 