.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 20;
}

.message-box {
    background-color: #ffffff;
    padding: 30px 40px;
    border-radius: 12px;
    text-align: center;
    max-width: 400px;
    width: 90%;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    position: relative;
    z-index: 21;
}

.title {
    font-size: 32px;
    margin-bottom: 10px;
}

.description {
    font-size: 18px;
    color: #333333;
    margin-bottom: 20px;
}

.button-group {
    display: flex;
    justify-content: center;
    gap: 20px;
}

.button-group button {
    min-width: 120px;
} 